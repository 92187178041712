import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/mdx-page';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`kactus.json`}</h1>
    <p>{`Placed at the root of your repository, this file contains the settings for Kactus.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "shareTextStyles": false, // whether to share the text styles across all your sketch files
  "shareLayerStyles": false, // whether to share the layer styles across all your sketch files
  "sharedPages": [ // the list of pages to share
    "name-of-the-page-to-share"
  ]
}
`}</code></pre>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      